import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class FaqQueryService {
    private GET_ALL_FAQ: any;

    constructor(public apollo: Apollo) {

        // Query to get all FAQs
        this.GET_ALL_FAQ = gql`
            query Faq {
                allFaqs(filter: {isDeletedYn: {equalTo: false}}) {
                    nodes {
                        faqId
                        question
                        answer
                        isDeletedYn
                        createdat
                        updatedat
                    }
                    totalCount
                }
            }
        `;

    }

    // Method to get all FAQs
    GET_FAQ(payload: any) {
        return this.apollo.watchQuery({
            query: this.GET_ALL_FAQ,
            variables: payload,
            context: {
                headers: {
                  'Authorization': 'Bearer YOUR_ACCESS_TOKEN'  
                }
            },
            fetchPolicy: 'network-only'
        });
    }
  

}
