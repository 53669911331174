import { Component, OnInit } from '@angular/core';
import { answerConst } from '../../constants';
import { FaqService } from './faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  formValues = answerConst;
  faqQuestionAnswerList = answerConst.faqList;
  isPageLoader: boolean = false;
  faqList: Array<{
    faqId: number | null;
    question: string;
    answer: string;
    isLoader: boolean;
    isOpen?: boolean;
  }> = [];

  constructor(private faqService: FaqService) {}

  ngOnInit(): void {
    this.getAllFaq();
  }

  getAllFaq() {
    this.isPageLoader = true;
    this.faqList = [];

    this.faqService.getFaqList().then((faqRes) => {
      if (faqRes && faqRes['nodes'] && Array.isArray(faqRes['nodes']) && faqRes['nodes'].length > 0) {
        this.setDataFormat(faqRes['nodes']);
        console.log("response : ", faqRes['nodes']);
      } else {
        this.isPageLoader = false;
      }
    }).catch(() => {
      this.isPageLoader = false;
    });
  }

  setDataFormat(response: any[]) {
    this.faqList = response.map(event => ({
      faqId: event.faqId || null,
      question: event.question || "",
      answer: event.answer || "",
      isLoader: false,
      isOpen: false 
    }));
    this.isPageLoader = false;
  }

  toggleAccordion(index: number) {
    
    this.faqList.forEach((faq, i) => {
      if (i !== index) faq.isOpen = false; 
    });

    this.faqList[index].isOpen = !this.faqList[index].isOpen;
  }
}
