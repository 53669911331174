import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FaqQueryService } from '../../gql/query/faq';
import { apiCalls } from '../../constants/apiCalls';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  apiCalls = apiCalls;

  constructor(private http: HttpClient,
    private faqQueryService: FaqQueryService) { }

  // Fetch the list of FAQs
  getFaqList() {
    let queryParam = {};
    
    //console.log('Sending GraphQL Query with Payload:', queryParam);
  
    return new Promise((resolve, reject) => {
      this.faqQueryService.GET_FAQ(queryParam).valueChanges
        .subscribe((response: any) => {
          console.log('GraphQL Response:', response);
          if (response && response.data && response.data.allFaqs) {
            resolve(response.data.allFaqs); 
          } else {
            resolve(null); 
          }
        }, err => {
          console.error('Error occurred while fetching FAQs:', err);
          reject(err);
        });
    });
  }
}
