import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqQueryService } from '../../gql/query/faq';
import { FaqService } from './faq.service';
import { FaqComponent } from './faq.component';



@NgModule({
  declarations: [],
  providers: [FaqService, FaqQueryService],
  imports: [
    CommonModule
  ]
})
export class FaqModule { }

