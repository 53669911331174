export const estimateHours = [
    {
        key: "three_day",
        label: "72 hours + $0.00",
        placeHolder: "72 hours",
        value: "72 hours",
        price: 0
    },
    {
        key: "two_day",
        label: "48 hours + $20.00",
        placeHolder: "48 hours",
        value: "48 hours",
        price: 20
    },
    {
        key: "one_day",
        label: "24 hours + $40.00",
        placeHolder: "24 hours",
        value: "24 hours",
        price: 40
    },
];

export const productContent = {
    productTitle: "speKwo Products",
    billingTitle: "Billing details",
    termsAndCondition: "I understand and agree to speKwo's terms and conditions.",
    products: {
        title: 'Products',
        ourTeam: 'OUR TEAM',
        ourTeamContent: `The speKwo team has extensive experience in building, remodeling and repair lists. Our team has
        personally reviewed over 2000 home inspection reports
        We pride ourselves on our reasonable and a 'level headed' approach to repairs.
        The speKwo estimates will provide you with accurate pricing for ANY item on the home inspection. The
        solution estimate will describe repair solutions and provide the cost of repairing that item.
        As with all of our solution estimates the price listed is the price the work can be completed for! We
        have Pros for every item who are licensed (for the work requiring it) and extremely proficient in all
        areas. If you decide to proceed with the quoted repairs they will typically begin within 7 days from the
        date of the request.`
    },
    preListSpekwo: {
        noHomeInsDesc: "If you don't have a home inspection report, no problem.  Just provide a description with a correlating picture for each item you would like quoted.",
        uploadPicsTitle: "Choose up to 10 items you would like us to quote:",
        moreThanTen: "Note if you have more then 10 items please check this box and hit next and we will contact you for an in-person quote.",
        preListInst: "Instructions",
        preListInst1: "Enter item number (start with 1) enter description of repair Choose file to upload pic",
        preListInst2: "Do you have another one, great click the plus button at the end of that row and your next row will pop up.",
    },
    info: {
        officeAddress1: "Raleigh, NC, US",
        officeAddress2: " Charlotte, NC, US",
        contactNumber: "866.646.0200",
        officeEmail: "info@spekwo.com",
        label: {
            address: "Address",
            phone: "Phone",
            email: "Email",
        }
    },
    billingForm: {
        title: "Billing details",
        formFields: {
            address: "Address",
            yourRole: "Your Role",
            yourName: "Your Name",
            yourCell: "Your Cell Number",
            yourEmail: "Your Email",
            clientName: "Client Name",
            buyerSeller: "Buyer/Seller",
            clientCell: "Client Cell Number",
            clientEmail: "Client Email",
            whoQuote: `Who would you like to receive quote (Please select one or more options below)`,
            me: "Me",
            buyerSellerQuote: "Buyer/Seller",
            others: "Others",
            note: "Please Note",
            note1: "We can only accept PDF of all documents. We cannot accept scanned images",
            note2: "Septic, Swimming Pools, Oil tanks, and asbestos are outside the scope of this quote",
            homeIns: "Home Inspection",
            ddra: "DDRA",
            wdoReport: "WDO Report",
            strctEng: "Structural Eng.",
            wellReport: "Radon Test Report",
            notesDesc: "Is there anything more you want us to know when creating your quote?",
            notesDescAddSpecific: "Are there specific item numbers we should know more about? Enter the item number and a note.",
            notesDescAddFull: "You want us to know something more?",
            notesDescAddFullAns: "We are planning to review all respective item numbers in your submitted report(s). However if there are specific things you feel we should be aware of you can enter them here. Enter the specific item number as it pertains to the home inspection report and provide us a brief description of the info you want to pass on. If you got nothing… Just click next.",
            upto40: "Choose up to 40 items you would like us to quote.",
            notes: "Notes",
            item: "Item #",
            description: "Description",
            clientPaying: "Client is paying (they will be sent payment link)",
            payingOnMe: "This one is on me(pay with card now) Total:",
            clientPayNote: "Your Quote will begin processing once payment has been received. ",
            nameOfCard: "Name on Card",
            cardNumber: "Card #",
            expDate: "Expire Date",
            expDateFormat: "MM/YYYY",
            promoCode: "Promo Code",
            cvcCode: 'CVC',
            applyPromo: "Apply Code",
            submitOrder: "Submit",
            localMarket: "Local Market",
            firmName: "Firm Name",
            affiliateName: " Affiliate Name",
            name: "Name",
            email: "Email",
            phone: "Phone"
        },
        error: {
            addressRequired: "Property Address Required",
            emailQuoteAlert: "Please select any one from below to receive your quote through email",
            emailMissedAlert: "Please Entered E-mail In Other Email Box",
            noFilesUpload: "Please upload any of the reports from below uploads",
            homeInsError: "You are selected home inspection but file not uploaded",
            ddraError: "You are selected DDRA but file not uploaded",
            wdoError: "You are selected WDO report but file not uploaded",
            struEngError: "You are selected structural eng but file not uploaded",
            wellError: "You are selected well report but file not uploaded",
            disableUpload: "Please select the report name",
            picUploadError: "More than two pictures not allowed for an item. But you are upload more than 2 pictures in item",
            estimateHourErr: "Estimate Hours required"
        },
        formStepper: {
            address: "Property Address",
            whatYourRole: "What Is Your Role?",
            yourInfo: "Your Info",
            whatQuote: "What Would You Like Quoted?",
            moreThanTen: "You Have More Than 10 Picture",
            orderAndSubmit: "Order And Payment"
        },
        roles: [
            {
                key: "Select Your Role",
                value: ""
            },
            {
                key: "Buyer Broker",
                value: "Buyer Broker"
            },
            {
                key: "Seller Broker",
                value: "Seller Broker"
            },
            {
                key: "Buyer TM",
                value: "Buyer TM"
            },
            {
                key: "Seller TM",
                value: "Seller TM"
            }
        ],
        indRoles: [
            {
                key: "Buyer",
                value: "Buyer"
            },
            {
                key: "Seller",
                value: "Seller"
            }
        ],
        states: [
            {
                key: "State",
                value: ""
            },
            {
                key: "Arizone",
                value: "Arizone"
            }
        ],
    },
    yourRole: [
        {
            key: "TC for seller",
            value: "TC for seller"
        },
        {
            key: "Listing Broker",
            value: "Listing Broker"
        },
        {
            key: "Seller",
            value: "Seller"
        },
        {
            key: "Home Inspector",
            value: "Home Inspector"
        },
        {
            key: "TC for Buyer",
            value: "TC for Buyer"
        },
        {
            key: "Buyer Broker",
            value: "Buyer Broker"
        },
        {
            key: "Buyer",
            value: "Buyer"
        },
        {
            key: "Property Manager",
            value: "Property Manager"
        },
    ],
    markets: {
        desc: "Looks like you are ready to get your house on the market. We would be happy to come out and provide an on-site quote.",
        projectsHighlight: "Please give us the highlights of the projects you have so we are prepared when we come out?",
        homeMarket: "When do you hope to put the home on the Market?",
        meetingInfo: "Who will be meeting us out there to review the needed work?"
    },
    payingOptions: [
        {
            key: "client_paying",
            value: "Client is paying (they will be sent payment link) Total: $"
        },
        {
            key: "paying_on_me",
            value: "This one is on me(pay with card now) Total: $"
        },
    ],
    request: 'REQUEST ',
    quote: ' QUOTE'
};

export const toastMessages = {
    orderSuccess: "Order Submitted Successfully",
    orderfailed: "Order Submitted failed",
    success: "success",
    failed: 'failed',
    warning: 'warning',
    orderFormatErr: "Order Details Stored Failed",
    invoiceSentToClientSuccess: 'Invoice Sent To Client Email success',
    invoiceSentToClientFailed: 'Invoice Sent To Client Email Failed',
    applyCodeError: "Apply Promo Code Getting Error",
    promoCodeNotAvaialble: "Promo Code You Entered Not Available",
    codeApplied: "Promo Code Applied And You Got",
    couponsSold: "All Coupons Are Sold Out. Currently This Coupon Not Applicable",
    cardTokenFailed: "Your Payment Is Failed",
    cardFailed: "Your Payment Is Failed",
    cardSuccess: "Your Payment Completed Successfully",
    specificNote: "Whoa… not so fast, we need to know What specific items you want us to quote. Please enter in at least one specific item number or  enter 40 items numbers.",
}

export const invoiceStatus = {
    completed: 'completed'
}

export const invoice = {
    paymentByCard: "checkout",
    paymentByClient: "custom_invoice"
}

export const productsImages = {
    HIQFULL: 'assets/img/services/full-spekwo-image.png',
    CGPYWCK: 'assets/img/services/specific-item-image.png',
    RJCZPSJ: 'assets/img/services/pre-list-image.png',
}

export const productsRoutes = {
    HIQFULL: '/products/full-spekwo',
    CGPYWCK: '/products/specific-item-spekwo',
    RJCZPSJ: '/products/pre-list-spekwo',
}

export const productsSku = {
    fullSpekwo: 'HIQFULL',
    specificItem: 'CGPYWCK',
    preList: 'RJCZPSJ',
}

export const productIcons = {
    HIQFULL: 'assets/img/services/full-spekwo-icon-view.png',
    CGPYWCK: 'assets/img/services/pre-list-icon1.png',
    RJCZPSJ: 'assets/img/services/specific-item-icon1.png'
}

export const rightArrowIcon = {
    arrowIcon: 'assets/img/services/right-arrow-icon.png'
}

